import * as React from 'react';
import {Helmet} from 'react-helmet';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {BgImage} from 'gbimage-bridge';
import {orderBy} from 'lodash';
import {useTranslation} from 'react-i18next';
import Layout from '../components/layout';
import {useAboutPage} from '../hooks/queries';
import {displayRichText} from '../utils';
import '../styles/pages/about.scss';
import {TestimonialCard, Button} from '../components';

const About = () => {
  const {
    contentfulAboutPage,
    allContentfulAboutTestimonials,
    allContentfulAboutPressLogos,
  } = useAboutPage();
  const {
    title,
    heroTitle,
    heroContent,
    punchLine,
    newsTitle,
    pressKitTitle,
    pressKitText,
    pressKitImage,
  } = contentfulAboutPage;
  // order the testimonials by the order field from contentful
  const testimonials = orderBy(
    allContentfulAboutTestimonials.nodes,
    ['order'],
    ['asc'],
  );

  // order the press logos by the order field from contentful
  const pressLogos = orderBy(
    allContentfulAboutPressLogos.nodes,
    ['order'],
    ['asc'],
  );

  const {t} = useTranslation();

  const pressKitBgImage = getImage(pressKitImage.localFile);
  const backgroundFluidImageStack = [
    `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))`,
    pressKitBgImage,
  ];

  const handleClick = () => {
    window.open('mailto:marketing@elselabs.io?subject=Media Inquiry', '_blank');
  };

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <div className="about tablet:pt-20 pt-14 w-full">
        <div className="about__hero flex flex-col justify-center mx-auto items-center">
          <div className="about__hero__title">
            <span className="tablet:text-6xl text-4xl font-semibold text-center">
              {displayRichText(heroTitle.raw)}
            </span>
          </div>
          <div className="about__hero__content desktop:text-xl tablet:text-lg text-base px-8 tablet:px-0">
            <p>{heroContent.heroContent}</p>
          </div>
        </div>

        <div className="about__punch tablet:py-20 py-10 px-3 flex justify-center">
          <div className="about__punch__content desktop:py-10 desktop:px-16 tablet:py-10 tablet:px-10 py-8 px-8">
            <p className="desktop:text-3xl text-2xl font-semibold tracking-tighter">
              {punchLine.punchLine}
            </p>
          </div>
        </div>

        <div className="about__news flex flex-col justify-center mx-auto items-center">
          <h2 className="tablet:text-5xl text-4xl text-center">{newsTitle}</h2>
          <div className="flex desktop:flex-row flex-col justify-center mt-8 mx-auto px-3">
            {testimonials.map(i => (
              <div className="m-2">
                <TestimonialCard
                  image={i.image}
                  text={i.text.text}
                  url={i.url}
                />
              </div>
            ))}
          </div>

          <div className="about__news__logos desktop:mt-14 tablet:mt-9 mt-8 mb-14 self-center">
            <div className="grid grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-4 items-center gap-y-10">
              {pressLogos.map(i => (
                <div className="flex justify-center">
                  <a href={i.url} target="_blank" rel="noreferrer">
                    <GatsbyImage
                      image={getImage(i.image.localFile)}
                      alt={i.image.description}
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <BgImage
            image={backgroundFluidImageStack}
            className="about__press w-full flex flex-col items-center justify-center">
            <h2 className="tablet:text-5xl text-4xl text-semibold mx-2 text-center">
              {pressKitTitle}
            </h2>
            <p className="desktop:text-2xl tablet:text-xl text-base mt-4 text-center px-8 font-semibold">
              {pressKitText}
            </p>
            <Button
              label={t('about:buttons.contact')}
              classes="about__press__button mt-8 desktop:text-lg text-base"
              handleClick={handleClick}
            />
          </BgImage>
        </div>
      </div>
    </Layout>
  );
};

export default About;
